@charset "UTF-8";

@font-face {
  font-family: "credoicons";
  src:url("fonts/credoicons.eot");
  src:url("fonts/credoicons.eot?#iefix") format("embedded-opentype"),
    url("fonts/credoicons.woff") format("woff"),
    url("fonts/credoicons.ttf") format("truetype"),
    url("fonts/credoicons.svg#credoicons") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "credoicons" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "credoicons" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-grid:before {
  content: "\61";
}
.icon-th-large:before {
  content: "\62";
}
.icon-task:before {
  content: "\63";
}
.icon-add-patients:before {
  content: "\64";
}
.icon-appointments:before {
  content: "\65";
}
.icon-daily-to-do-list:before {
  content: "\66";
}
.icon-earnings-ledgers:before {
  content: "\67";
}
.icon-executive-summary:before {
  content: "\68";
}
.icon-health-records:before {
  content: "\69";
}
.icon-prescription:before {
  content: "\6a";
}
.icon-profile-settings:before {
  content: "\6b";
}
.icon-statistics:before {
  content: "\6c";
}
.icon-view-approve:before {
  content: "\6d";
}
.icon-view-meal-plan:before {
  content: "\6e";
}
.icon-view-medications:before {
  content: "\6f";
}
.icon-view-todo:before {
  content: "\70";
}
.icon-dashboard-1:before {
  content: "\72";
}
.icon-dashboard:before {
  content: "\71";
}
.icon-notification:before {
  content: "\73";
}
.icon-rupee:before {
  content: "\74";
}
.icon-meter:before {
  content: "\75";
}
.icon-add-member:before {
  content: "\76";
}
.icon-add-patients-1:before {
  content: "\77";
}
.icon-appointments-1:before {
  content: "\78";
}
.icon-assign-care-team:before {
  content: "\79";
}
.icon-create-appointmnet:before {
  content: "\7a";
}
.icon-create-meal-plan:before {
  content: "\41";
}
.icon-create-to-do:before {
  content: "\43";
}
.icon-daily-to-do-list-1:before {
  content: "\44";
}
.icon-dashboard-2:before {
  content: "\45";
}
.icon-earnings-ledgers-1:before {
  content: "\46";
}
.icon-executive-summary-1:before {
  content: "\47";
}
.icon-health-records-1:before {
  content: "\48";
}
.icon-lead-listing:before {
  content: "\49";
}
.icon-manage-data:before {
  content: "\4a";
}
.icon-manage-users:before {
  content: "\4b";
}
.icon-medication:before {
  content: "\4c";
}
.icon-meter-1:before {
  content: "\4d";
}
.icon-my-appointmnet:before {
  content: "\4e";
}
.icon-notification-1:before {
  content: "\4f";
}
.icon-onboarding:before {
  content: "\50";
}
.icon-prescription-1:before {
  content: "\51";
}
.icon-profile-settings-1:before {
  content: "\52";
}
.icon-rupee-1:before {
  content: "\53";
}
.icon-statistics-1:before {
  content: "\54";
}
.icon-uploaded-prescription:before {
  content: "\55";
}
.icon-view-approve-1:before {
  content: "\56";
}
.icon-view-meal-plan-1:before {
  content: "\58";
}
.icon-view-medications-1:before {
  content: "\59";
}
.icon-view-todo-1:before {
  content: "\5a";
}
.icon-lab:before {
  content: "\42";
}
.icon-approval:before {
  content: "\57";
}
.icon-approval-1:before {
  content: "\30";
}
.icon-profile-settings-2:before {
  content: "\31";
}
.icon-download:before {
  content: "\32";
}
.icon-lipid-icon:before {
  content: "\33";
}
.icon-credo-logo-with-tagline:before {
  content: "\34";
}
.icon-chat:before {
  content: "\35";
}
.icon-consults:before {
  content: "\36";
}
.icon-consults:before {
  content: "\36";
}
.icon-vector:before {
  content: "\37";
}
