.font-16 {
  font-size: 16px;
}
.font-12 {
  font-size: 12px;
}
.font-14 {
  font-size: 14px;
}
.font-10 {
  font-size: 10px;
}
.font-17 {
  font-size: 17px;
}
.font-18 {
  font-size: 18px;
}
.font-20 {
  font-size: 20px;
}
.font-22 {
  font-size: 22px;
}
.font-24 {
  font-size: 24px;
}

// border-radius

.rounded-3 {
  border-radius: 3px;
}
.rounded-4 {
  border-radius: 4px;
}
.rounded-5 {
  border-radius: 5px;
}
.rounded-6 {
  border-radius: 6px;
}
.rounded-7 {
  border-radius: 7px;
}
.rounded-8 {
  border-radius: 8px;
}
.rounded-9 {
  border-radius: 9px;
}
.rounded-10 {
  border-radius: 10px;
}
.rounded-11 {
  border-radius: 11px;
}
.rounded-12 {
  border-radius: 12px;
}


// Type display classes
.display-5 {
  font-size: $display5-size;
  font-weight: $display5-weight;
  line-height: $display-line-height;
}
.display-6 {
  font-size: $display6-size;
  font-weight: $display6-weight;
  line-height: $display-line-height;
}
.display-7 {
  font-size: $display7-size;
  font-weight: $display7-weight;
  line-height: $display-line-height;
}

.blockquote {
  border-left: 5px solid $primary;
  border: 1px solid $border-color;
  padding: 15px;
}
